import React from 'react'
import { useRouter } from 'next/router'
import styled from '@emotion/styled'
import LogoWT from '@/assets/img/logo/LOGO_WT'
import ArrowBack from '@/assets/img/icons/Arrow_Back.svg'
import { Colors } from '@/constants/styles/color'

const StyledContainer = styled.div<{ isDark: boolean; padding?: string }>`
  display: flex;
  justify-content: center;
  padding: ${props => (props.padding ? props.padding : '0 15px')};
  background: ${props => (props.isDark ? Colors.WHITE : Colors.BLACK)};
  height: 56px;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
`

type Props = {
  isDark: boolean | undefined
  padding?: string
  enableBack?: boolean
  onPressBack?: () => void
}

const FanmeHeader = ({ enableBack, padding, onPressBack, isDark = false }: Props) => {
  const router = useRouter()
  return (
    <>
      <StyledContainer isDark={isDark} padding={padding}>
        {onPressBack && (
          <ArrowBack
            fill={Colors.WHITE}
            style={{
              margin: '16px auto 16px 0',
            }}
            onClick={onPressBack}
          />
        )}
        <LogoWT height="22px" margin="auto" />
        {enableBack && (
          <ArrowBack
            fill={Colors.DARK_GRAY}
            style={{
              margin: '16px 0 16px auto',
              visibility: 'collapse',
            }}
            onClick={() => router.back()}
          />
        )}
      </StyledContainer>
    </>
  )
}

export default FanmeHeader
