import React from 'react'
import getConfig from 'next/config'
import Router from 'next/router'
import { Button, Container, Text } from '@nextui-org/react'
import { Colors, Gradation } from '@/constants/styles/color'
import NotFoundIcon from '@/assets/img/icons/NotFound.svg'
import FanmeHeader from '../../../molecules/Header/FanmeHeader'

const { publicRuntimeConfig } = getConfig()

const PageNotFound = () => {
  return (
    <>
      <FanmeHeader isDark={false} />
      <Container
        css={{
          d: 'flex',
          fd: 'column',
          background: Gradation.PT_LIGHT_30,
          height: 'calc(100vh - 56px)',
        }}
      >
        <NotFoundIcon
          fill={Colors.LIGHT_GRAY}
          style={{
            margin: 'auto auto 0 auto',
          }}
        />
        <Text
          size={18}
          color={Colors.BLACK}
          weight="bold"
          css={{
            margin: '32px auto 24px auto',
          }}
        >
          ページが見つかりません
        </Text>
        <Text
          size={14}
          color={Colors.BLACK}
          css={{
            lineHeight: '24px',
            marginLeft: '12px',
            marginRight: '12px',
          }}
        >
          <br /> 一時的にアクセスできないか、URLが変更された可能性があります。
          <br /> 申し訳ございませんが、再度読み込みをお試しください。
        </Text>
        <Button
          size="lg"
          css={{
            margin: 'auto auto 56px auto',
            bg: Colors.WHITE,
            border: `1px solid ${Colors.BLACK}`,
            color: Colors.BLACK,
            width: '100%',
          }}
          rounded
          onClick={() => Router.push(`${publicRuntimeConfig.FRONT_URL}`)}
        >
          トップページに戻る
        </Button>
      </Container>
    </>
  )
}

export default PageNotFound
