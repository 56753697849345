import React from 'react'

type Props = {
  height?: string
  margin?: string
}

const LogoWT = ({ height, margin }: Props) => {
  return (
    <svg
      viewBox="0 0 108 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        height: height ? height : '',
        margin: margin ? margin : '',
      }}
    >
      <g clipPath="url(#clip0_8_133)">
        <path
          d="M20.5713 0H0L3.4287 3.4287H17.1429V6.8571H6.8571V10.2858H17.1429V13.7142H6.8571L10.2858 17.1429H20.5713V0Z"
          fill="url(#paint0_linear_8_133)"
        />
        <path
          d="M6.8571 13.7142V20.5713H3.4287V3.4287L0 0V24H10.2858V17.1429L6.8571 13.7142Z"
          fill="url(#paint1_linear_8_133)"
        />
        <path d="M22.0374 22.5V24H65.8374L64.3374 22.5H22.0374Z" fill="url(#paint2_linear_8_133)" />
        <path d="M107.162 22.5H64.3374L65.8374 24H107.162V22.5Z" fill="url(#paint3_linear_8_133)" />
        <path
          d="M30.5874 3L22.0374 21H26.5212L28.338 17.175H37.1118L38.9289 21H43.4124L34.8624 3H30.5874ZM29.9412 13.8L32.7249 7.9392L35.5089 13.8H29.9412Z"
          fill="white"
        />
        <path
          d="M58.6374 14.0787L49.3413 3H45.8124V21H49.8624V9.9213L59.1588 21H62.6874V3H58.6374V14.0787Z"
          fill="white"
        />
        <path
          d="M107.162 6.375V3H93.6624V21H107.162V17.625H97.7124V13.6875H106.037V10.3125H97.7124V6.375H107.162Z"
          fill="white"
        />
        <path
          d="M78.1749 13.125L71.0853 3H67.4874V21H71.5374V10.7061L77.4132 19.0971H78.9369L84.8124 10.7061V21H88.8624V3H85.2645L78.1749 13.125Z"
          fill="white"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_8_133"
          x1="6"
          y1="12.8571"
          x2="19.7142"
          y2="-0.8571"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#8C1EE6" />
          <stop offset="0.5" stopColor="#FF55AF" />
          <stop offset="1" stopColor="#FFC814" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_8_133"
          x1="-3.4287"
          y1="20.5713"
          x2="8.5713"
          y2="8.5713"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0050D2" />
          <stop offset="0.5" stopColor="#00B9E6" />
          <stop offset="1" stopColor="#00E69B" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_8_133"
          x1="32.6124"
          y1="34.575"
          x2="54.5124"
          y2="12.675"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0050D2" />
          <stop offset="0.5" stopColor="#00B9E6" />
          <stop offset="1" stopColor="#00E69B" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_8_133"
          x1="75.4188"
          y1="33.5811"
          x2="96.8313"
          y2="12.1686"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#8C1EE6" />
          <stop offset="0.5" stopColor="#FF55AF" />
          <stop offset="1" stopColor="#FFC814" />
        </linearGradient>
        <clipPath id="clip0_8_133">
          <rect width="108" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default LogoWT
