import React from 'react'
import Head from 'next/head'
import { Container, Text } from '@nextui-org/react'

import { Colors } from '@/constants/styles/color'
import IncompleteIcon from '@/assets/img/icons/Incomplete.svg'
import FanmeHeader from '../../../molecules/Header/FanmeHeader'

const ServerError = () => {
  return (
    <>
      <Head>
        <meta httpEquiv="refresh" content="3; url=/" />
      </Head>
      <FanmeHeader isDark={false} />
      <Container
        css={{
          d: 'flex',
          fd: 'column',
          ai: 'center',
          paddingTop: '80px',
          h: 'calc(100vh - 56px)',
          background: Colors.ULTRA_LIGHT_GRAY,
        }}
      >
        <IncompleteIcon />
        <Text
          size={18}
          color={Colors.BLACK}
          weight="bold"
          css={{
            margin: '40px auto 24px auto',
          }}
        >
          サーバーエラーが発生しています
        </Text>
        <Text
          size={14}
          color={Colors.BLACK}
          css={{
            lh: '24px',
            textAlign: 'center',
            span: {
              display: 'inline-block',
            },
          }}
        >
          <span>申し訳ありません。サーバーで問題が</span>
          <span>発生しているためページを表示できません。</span>
          <br />
          <span>しばらく時間をおいてからやり直してください。</span>
        </Text>
      </Container>
    </>
  )
}

export default ServerError
